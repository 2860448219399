import React, { FC } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import useWindowDimensions from '../../hooks/useWindowDimensions';


import "./ImageText.css"
import { Link } from 'gatsby';

type ImageTextProps = {
    header_text: string,
    text: string,
    image_left: boolean,
    image: IGatsbyImageData,
    hasButton: [boolean, string, string]
};

const ImageText: FC<ImageTextProps> = ({ header_text, text, image_left, image, hasButton }) => {
    const { width } = useWindowDimensions();
    

    // if (width > 768){
    //     return (
    //         <div className='meta-container'>
    //             <div className={`meta-body ${image_left ? 'image_left' : 'reverse_row'}`}>
    //                 <div className='meta-image-container'>
    //                     <GatsbyImage className={`meta-image ${image_left ? 'image_left' : 'meta-image-left'}`} alt='' image={image}></GatsbyImage>
    //                 </div>
    //                 <div className='meta-content'>
    //                     <StaticImage className={`palmleaf_icon ${!image_left ? 'image_left' : 'palmleaf_icon_right'}`}
    //                     src='../../images/Palmleaf.png'
    //                     quality={95}
    //                     formats={['auto', 'webp', 'avif']}
    //                     loading='eager'
    //                     alt='Intro banner'
    //                     style={{position: 'absolute'}}
    //                 />
    //                     <div className={`text_container ${image_left ? 'image_left' : 'text_left'}`}>
    //                         <h4 className={`text_header ${image_left ? 'image_left' : 'text_left'}`}>{header_text}</h4>
    //                         <p className={`text_content ${image_left ? 'image_left' : 'text_left'}`}>{text}</p>
    //                     </div>

    //                 </div>
    //             </div>

    //         </div>
    //     );
    // }

    if (width <= 768) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff' }}>
                <GatsbyImage style={{ margin: '-20px 20px 0 20px' }} className={`meta-image`} alt='' image={image}></GatsbyImage>
                <div className='meta-content'>

                    <div className={`text_container`}>
                        <h4 className={`text_header image_text_header`}>{header_text}</h4>
                        <p className={`text_content image_text_content`}>{text}</p>
                    </div>
                    <div className={`${hasButton[0] ? 'button_box' : 'no_display'}`}>
                        <div className='read_more_btn' style={{ height: '50px', width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Link style={{ color: '#fff', textDecoration: 'none', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} to={hasButton[1]}>{hasButton[2]}</Link>
                        </div>
                    </div>  

                </div>
            </div>
        )
    }

    else {
        return (
            <div className='meta-container'>
                <div className={`meta-body ${image_left ? 'image_left' : 'reverse_row'}`}>
                    <div className='meta-image-container'>
                        <GatsbyImage className={`meta-image ${image_left ? 'image_left' : 'meta-image-left'}`} alt='' image={image}></GatsbyImage>
                    </div>
                    <div className='meta-content'>
                        <StaticImage className={`palmleaf_icon ${!image_left ? 'image_left' : 'palmleaf_icon_right'}`}
                            src='../../images/Palmleaf.png'
                            quality={95}
                            formats={['auto', 'webp', 'avif']}
                            loading='eager'
                            alt='Intro banner'
                            style={{ position: 'absolute' }}
                        />
                        <div className={`text_container ${image_left ? 'image_left' : 'text_left'}`}>
                            <h4 className={`text_header ${image_left ? 'image_left' : 'text_left'}`}>{header_text}</h4>
                            <p className={`text_content ${image_left ? 'image_left' : 'text_left'}`}>{text}</p>
                        </div>
                        <div className={`${hasButton[0] ? 'button_box' : 'no_display'}`}>
                            <div className='read_more_btn' style={{ height: '50px', width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Link style={{ color: '#fff', textDecoration: 'none', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} to={hasButton[1]}>{hasButton[2]}</Link>
                            </div>
                        </div>  

                    </div>

                </div>

            </div>
        );
    }


}

export default ImageText;